import { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import ReCAPTCHA from 'react-google-recaptcha';
import { FiPhone, FiSmartphone, FiMail, FiMapPin } from 'react-icons/fi';
import { Helmet } from 'react-helmet';

export default function Contact() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    emailjs.init(process.env.REACT_APP_EMAILJS_USER_ID);
  }, []);

  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [message, setMessage] = useState({ text: '', type: '' });
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    message: ''
  });
  const [captchaToken, setCaptchaToken] = useState('');

  const handleCaptcha = async (token) => {
    setCaptchaToken(token);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.firstName || !formData.lastName || !formData.email || !formData.message) {
      setMessage({ text: 'Por favor, completa todos los campos.', type: 'error' });
      return;
    }

    if (!acceptedTerms) {
      setMessage({ text: 'Debes aceptar los Términos y Condiciones y la Política de Privacidad.', type: 'error' });
      return;
    }

    if (!captchaToken) {
      setMessage({ text: 'Por favor, verifica el CAPTCHA.', type: 'error' });
      return;
    }

    emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      formData,
      process.env.REACT_APP_EMAILJS_USER_ID
    )
      .then((response) => {
        setMessage({ text: 'Tu mensaje ha sido enviado correctamente.', type: 'success' });
      })
      .catch((error) => {
        setMessage({ text: 'Hubo un error al enviar el mensaje.', type: 'error' });
      });

    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      message: '',
    });
    setCaptchaToken('');
    setAcceptedTerms(false);
  };

  return (
    <div>
      <Helmet>
        <title>Contacto</title>
        <meta
          name="description"
          content="¿Tienes alguna pregunta o sugerencia? Contáctanos y te responderemos lo antes posible. Estamos aquí para ayudarte."
        />
      </Helmet>

      <div className="isolate bg-white px-6 py-24 sm:py-32 lg:px-8 font-nunito">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl mb-4">Contáctanos</h2>
          <p className="text-lg text-gray-600">Estamos aquí para responder a tus preguntas y ayudarte en lo que necesites.</p>
        </div>

        <form onSubmit={handleSubmit} className="mx-auto mt-16 w-full max-w-lg sm:mt-20">
          <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
            <div>
              <label htmlFor="first-name" className="block text-sm font-semibold text-gray-900">
                Nombre
              </label>
              <div className="mt-2.5">
                <input
                  id="first-name"
                  name="firstName"
                  type="text"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  required
                  className="block w-full appearance-none rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-rose-600 sm:text-sm"
                />
              </div>
            </div>
            <div>
              <label htmlFor="last-name" className="block text-sm font-semibold text-gray-900">
                Apellido
              </label>
              <div className="mt-2.5">
                <input
                  id="last-name"
                  name="lastName"
                  type="text"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  required
                  className="block w-full appearance-none rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-rose-600 sm:text-sm"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="email" className="block text-sm font-semibold text-gray-900">
                Correo Electrónico
              </label>
              <div className="mt-2.5">
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                  className="block w-full appearance-none rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-rose-600 sm:text-sm"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="message" className="block text-sm font-semibold text-gray-900">
                Mensaje
              </label>
              <div className="mt-2.5">
                <textarea
                  id="message"
                  name="message"
                  rows={4}
                  value={formData.message}
                  onChange={handleInputChange}
                  required
                  className="block w-full appearance-none rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-rose-600 sm:text-sm"
                />
              </div>
            </div>
            <div className="sm:col-span-2 flex items-center">
              <input
                id="terms-privacy"
                name="terms-privacy"
                type="checkbox"
                checked={acceptedTerms}
                onChange={() => setAcceptedTerms(!acceptedTerms)}
                required
                className="h-4 w-4 text-rose-600 border-gray-300 rounded focus:ring-rose-500"
              />
              <label htmlFor="terms-privacy" className="ml-2 text-sm text-gray-600">
                Acepto los{" "}
                <a href="/terms-and-conditions" className="text-rose-600 hover:underline">
                  Términos y Condiciones
                </a>{" "}
                y la{" "}
                <a href="/privacy-policy" className="text-rose-600 hover:underline">
                  Política de Privacidad
                </a>
              </label>
            </div>
            <div className="mt-6 sm:col-span-2 flex items-center">
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_ID}
                onChange={handleCaptcha}
              />
            </div>
          </div>
          <div className="mt-10">
            <button
              type="submit"
              className="block w-full rounded-md bg-rose-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-rose-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-rose-600"
            >
              Enviar
            </button>
          </div>
          {message.text && (
            <div className={`mt-4 text-sm ${message.type === 'error' ? 'text-red-600' : 'text-green-600'}`}>
              <p>{message.text}</p>
            </div>
          )}
        </form>

        <div className="mx-auto max-w-2xl text-center mt-10">
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-3xl mb-6">Otras opciones de contacto</h2>

          {/* Contenedor con grid para 2 elementos por fila, responsive */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 justify-items-center">

            {/* Opción de Teléfono fijo */}
            <div className="flex flex-col items-center justify-center bg-white border-2 border-rose-600 shadow-md rounded-3xl p-6 w-full max-w-xs">
              <FiPhone className="text-rose-600 w-8 h-8 mb-4" />
              <div className="text-left">
                <p className="text-lg font-medium">
                  938 065 036
                </p>
              </div>
            </div>

            {/* Opción de Teléfono móvil */}
            <div className="flex flex-col items-center justify-center bg-white border-2 border-rose-600 shadow-md rounded-3xl p-6 w-full max-w-xs">
              <FiSmartphone className="text-rose-600 w-8 h-8 mb-4" />
              <div className="text-left">
                <p className="text-lg font-medium">
                  +34 638 061 776
                </p>
              </div>
            </div>

            {/* Opción de Correo */}
            <div className="flex flex-col items-center justify-center bg-white border-2 border-rose-600 shadow-md rounded-3xl p-6 w-full max-w-xs">
              <FiMail className="text-rose-600 w-8 h-8 mb-4" />
              <div className="text-left">
                <p className="text-lg font-medium">
                  info.media4sports@gmail.com
                </p>
              </div>
            </div>

            {/* Opción de Dirección */}
            <div className="flex flex-col items-center justify-center bg-white border-2 border-rose-600 shadow-md rounded-3xl p-6 w-full max-w-xs">
              <FiMapPin className="text-rose-600 w-8 h-8 mb-4" />
              <div className="text-left">
                <p className="text-sm">
                  Calle Orient 78, 3o 8a
                  <br />
                  Sant Cugat del Vallès, España
                  <br />
                  08173
                </p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}
