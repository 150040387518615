import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import gestion from '../images/new Icons/redes2.webp';
import ads from '../images/new Icons/ads2.webp';
import content from '../images/new Icons/contenido2.webp';
import web from '../images/new Icons/web2.webp';
import city from '../images/webp/city3.webp';
import cliente1 from '../images/cliente2.webp';
import cliente2 from '../images/cliente3.webp';
import cliente4 from '../images/cliente6.webp';
import cliente5 from '../images/qars.webp';

import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function Services() {
  const [hasViewedSection, setHasViewedSection] = useState(false);

  const { ref, inView } = useInView({
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView && !hasViewedSection) {
      setHasViewedSection(true);
    }
  }, [inView, hasViewedSection]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <title>Servicios</title>
        <meta name="description" content="Descubre los servicios de marketing digital que ofrecemos para potenciar tu marca deportiva." />
      </Helmet>

      <div className="font-nunito bg-gray-100">

        {/* Header Section */}
        <section className="relative h-[70vh] bg-black">
          <img src={city} alt="City" className="absolute inset-0 w-full h-full object-cover z-0" loading="lazy" />
          <div className="absolute inset-0 z-0 bg-black/30 backdrop-blur-[1px]" />
          <div className="absolute inset-0 z-0 bg-gradient-to-b from-transparent to-black opacity-40" />

          {/* Ajuste de altura en móviles con h-auto */}
          <section className="flex flex-col items-center justify-center relative h-[70vh] mx-auto px-2 sm:px-4 overflow-hidden mb-8">

            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
              className="text-white text-center z-10 w-full max-w-[1400px] px-4 sm:px-8 md:px-16 lg:px-56"
            >
              <motion.h1
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1.5, delay: 0.5 }}
                className="text-3xl sm:text-5xl md:text-6xl lg:text-7xl font-extrabold mb-6 md:mb-10 text-white font-raleway"
              >
                Nuestros Servicios
              </motion.h1>
              <motion.p
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1.5, delay: 1 }}
                className="text-lg sm:text-xl md:text-2xl lg:text-3xl mb-8 md:mb-8 font-raleway font-semibold"
              >
                Ofrecemos soluciones personalizadas para maximizar tu presencia en <span className="text-rose-700">redes sociales</span>.
              </motion.p>
            </motion.div>
          </section>
        </section>

        {/* División Ondulada */}
        <div className="relative bg-white">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 110"
            className="absolute bottom-0 left-0 w-full"
          >
            <path
              fill="#f3f4f6"
              fillOpacity="1"
              d="M0,90 C360,120 1080,40 1440,80 L1440,320 L0,320 Z"
            />
          </svg>
        </div>

        {/* Services Section */}
        <section className="py-20 mx-8 md:mx-16" ref={ref}> {/* Aumentado el margen horizontal a mx-8 y md:mx-16 */}
          <div className="container mx-auto text-center">
            <div className="flex flex-wrap justify-center">

              {/* Service 1 */}
              <motion.div
                className="w-full md:w-2/3 px-4 mb-8"
                initial={{ opacity: 0, y: 50 }}
                animate={hasViewedSection ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
                transition={{ duration: 1, delay: 0.2 }}
              >
                <div className="bg-white shadow-lg rounded-3xl py-12 px-6 hover:scale-105 transform transition-transform duration-500 flex flex-col md:flex-row items-center md:mx-8">
                  {/* Contenedor de texto */}
                  <div className="md:w-2/3 text-center md:text-left md:pr-8 p-6 md:p-8"> {/* Añadido p-6 para más espacio en los bordes */}
                    <h3 className="font-raleway text-3xl md:text-4xl font-extrabold mb-6">Gestión de Redes Sociales</h3>
                    <p className="text-lg md:text-xl text-gray-600 mb-6">
                      Nos encargamos de gestionar tus redes sociales y crear contenido de alta calidad para ti. Desde la planificación hasta la publicación, nos ocupamos de todo para mantener tu presencia en línea activa y atractiva, asegurando que tu marca se conecte de manera efectiva con tu audiencia.
                    </p>
                    <p className="text-lg md:text-xl text-gray-600">
                      Analizamos de forma continua el desempeño de tus redes sociales, las tendencias actuales y las preferencias de tu audiencia, optimizando cada detalle para garantizar que tu contenido siempre sea relevante y logre los mejores resultados.
                    </p>
                  </div>
                  {/* Contenedor de imagen */}
                  <div className="md:w-1/3 flex justify-center md:ml-8"> {/* Añadido md:ml-8 para darle un margen a la izquierda */}
                    <img src={gestion} alt="Gestión de Redes Sociales" className="h-32 md:h-40 lg:h-48" loading="lazy" />
                  </div>
                </div>
              </motion.div>

              {/* Service 2 */}
              <motion.div
                className="w-full md:w-2/3 px-4 mb-8"
                initial={{ opacity: 0, y: 50 }}
                animate={hasViewedSection ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
                transition={{ duration: 1, delay: 0.2 }}
              >
                <div className="bg-white shadow-lg rounded-3xl py-12 px-6 hover:scale-105 transform transition-transform duration-500 flex flex-col md:flex-row items-center md:mx-8">
                  {/* Contenedor de texto */}
                  <div className="md:w-2/3 text-center md:text-left md:pr-8 p-6 md:p-8"> {/* Añadido p-6 para más espacio en los bordes */}
                    <h3 className="font-raleway text-3xl md:text-4xl font-extrabold mb-6">Estrategias de Google Ads</h3>
                    <p className="text-lg md:text-xl text-gray-600 mb-6">
                      Maximiza tu inversión publicitaria con estrategias de Google Ads diseñadas a la medida. Creamos y gestionamos campañas que llegan a la audiencia correcta, con el mensaje adecuado, para impulsar las conversiones y generar resultados medibles.
                    </p>
                    <p className="text-lg md:text-xl text-gray-600">
                      Desde el análisis de palabras clave hasta la optimización continua, aplicamos las mejores prácticas para garantizar que tu marca sea visible donde y cuando más importa.
                    </p>
                  </div>
                  {/* Contenedor de imagen */}
                  <div className="md:w-1/3 flex justify-center md:ml-8"> {/* Añadido md:ml-8 para darle un margen a la izquierda */}
                    <img src={ads} alt="Gestión de Redes Sociales" className="h-32 md:h-40 lg:h-48" loading="lazy" />
                  </div>
                </div>
              </motion.div>

              {/* Service 3 */}
              <motion.div
                className="w-full md:w-2/3 px-4 mb-8"
                initial={{ opacity: 0, y: 50 }}
                animate={hasViewedSection ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
                transition={{ duration: 1, delay: 0.2 }}
              >
                <div className="bg-white shadow-lg rounded-3xl py-12 px-6 hover:scale-105 transform transition-transform duration-500 flex flex-col md:flex-row items-center md:mx-8">
                  {/* Contenedor de texto */}
                  <div className="md:w-2/3 text-center md:text-left md:pr-8 p-6 md:p-8"> {/* Añadido p-6 para más espacio en los bordes */}
                    <h3 className="font-raleway text-3xl md:text-4xl font-extrabold mb-6">Producción de Contenido</h3>
                    <p className="text-lg md:text-xl text-gray-600 mb-6">
                      Captura la atención de tu audiencia con contenido visual y escrito que refleje la esencia de tu marca. Nos especializamos en producir videos, imágenes y textos que cuentan tu historia y fortalecen la conexión emocional con tu audiencia.
                    </p>
                    <p className="text-lg md:text-xl text-gray-600">
                      Cada pieza de contenido es creada con un enfoque estratégico, alineada a tus objetivos de negocio y optimizada para tener impacto en todas las plataformas digitales.
                    </p>
                  </div>
                  {/* Contenedor de imagen */}
                  <div className="md:w-1/3 flex justify-center md:ml-8"> {/* Añadido md:ml-8 para darle un margen a la izquierda */}
                    <img src={content} alt="Gestión de Redes Sociales" className="h-32 md:h-40 lg:h-48" loading="lazy" />
                  </div>
                </div>
              </motion.div>

              {/* Service 3 */}
              <motion.div
                className="w-full md:w-2/3 px-4 mb-8"
                initial={{ opacity: 0, y: 50 }}
                animate={hasViewedSection ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
                transition={{ duration: 1, delay: 0.2 }}
              >
                <div className="bg-white shadow-lg rounded-3xl py-12 px-6 hover:scale-105 transform transition-transform duration-500 flex flex-col md:flex-row items-center md:mx-8">
                  {/* Contenedor de texto */}
                  <div className="md:w-2/3 text-center md:text-left md:pr-8 p-6 md:p-8">
                    <h3 className="font-raleway text-3xl md:text-4xl font-extrabold mb-6">Desarrollo de Páginas Web</h3>
                    <p className="text-lg md:text-xl text-gray-600 mb-6">
                      Creamos sitios web a medida que combinan diseño atractivo y funcionalidad intuitiva. Nuestro enfoque está orientado a ofrecer soluciones que representen la identidad de tu marca y proporcionen una experiencia de usuario optimizada.
                    </p>
                    <p className="text-lg md:text-xl text-gray-600">
                      Desde la conceptualización hasta el lanzamiento, cuidamos cada detalle para que tu página web destaque en el entorno digital y esté preparada para lograr tus objetivos empresariales.
                    </p>
                  </div>
                  {/* Contenedor de imagen */}
                  <div className="md:w-1/3 flex justify-center md:ml-8">
                    <img src={web} alt="Desarrollo de Páginas Web" className="h-32 md:h-40 lg:h-48" loading="lazy" />
                  </div>
                </div>
              </motion.div>

            </div>
          </div>
        </section>

        <section className="py-12 bg-white">
          <div className="container mx-auto text-center">
            <motion.h2
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
              className="font-raleway text-2xl sm:text-3xl md:text-4xl font-bold mb-8"
            >
              Marcas que han trabajado con nosotros
            </motion.h2>

            <div className="flex flex-wrap justify-center">
              <motion.img
                src={cliente1}
                alt="Logo Cliente 1 - [Sabadell Cup]"
                className="h-20 mx-4 sm:mx-6 md:mx-8 my-2"
                loading="lazy"
                initial={{ opacity: 0, transition: { duration: 1 } }}
                animate={{ opacity: 1, transition: { duration: 1 } }}
              />
              <motion.img
                src={cliente2}
                alt="Logo Cliente 4  - [Bigrill]"
                className="h-20 mx-4 sm:mx-6 md:mx-8 my-2"
                loading="lazy"
                initial={{ opacity: 0, transition: { duration: 1 } }}
                animate={{ opacity: 1, transition: { duration: 1 } }}
              />
              <motion.img
                src={cliente4}
                alt="Logo Cliente 7 - [Standecor]"
                className="h-20 mx-4 sm:mx-6 md:mx-8 my-2"
                loading="lazy"
                initial={{ opacity: 0, transition: { duration: 1 } }}
                animate={{ opacity: 1, transition: { duration: 1 } }}
              />
              <motion.img
                src={cliente5}
                alt="Logo Cliente 8 - [101Pisos]"
                className="h-20 mx-4 sm:mx-6 md:mx-8 my-2"
                loading="lazy"
                initial={{ opacity: 0, transition: { duration: 1 } }}
                animate={{ opacity: 1, transition: { duration: 1 } }}
              />
            </div>
          </div>
        </section>

        {/* CTA Section */}
        <section className="py-16 bg-white text-center">
          <motion.h2
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 0.8 }}
            className="text-3xl font-bold mb-6"
          >
            ¿Estás listo para potenciar tu estrategia de marketing?
          </motion.h2>
          <Link to="/contact">
            <motion.button
              aria-label="Contacta a Media4Sports"
              whileHover={{ scale: 1.1, transition: { duration: 0.5 } }}
              whileTap={{ scale: 0.9, transition: { duration: 0.5 } }}
              className="bg-rose-600 hover:bg-rose-700 text-white font-lightbold py-4 px-8 rounded-full"
            >
              Contáctanos
            </motion.button>
          </Link>
        </section>
      </div>
    </div>
  );
}

export default Services;